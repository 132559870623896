(function($){
  'use strict';
  
  let defaultOptions = {
    serverPath: '',
    fileFieldName: 'fileToUpload',
    data: [],                     
    headers: {},                  
    xhrFields: {},                
    urlPropertyName: 'file',      
    statusPropertyName: 'success',
    success: undefined,           
    error: undefined,             
    imageWidthModalEdit: false    
  };
  
  function getDeep(object, propertyParts) {
    let mainProperty = propertyParts.shift(),
    otherProperties = propertyParts;
    
    if (object !== null) {
      if (otherProperties.length === 0) return object[mainProperty];
      if (typeof object === 'object') return getDeep(object[mainProperty], otherProperties);
    }
    return object;
  }
  
  addXhrProgressEvent();
  
  $.extend(true, $.trumbowyg, {
    langs: {
      en: { figcaption: 'Image with Caption', file: 'File', uploadError: 'Error'}
    },
    plugins: {
      figcaption: {
        init: function (trumbowyg) {
          trumbowyg.o.plugins.figcaption = $.extend(true, {}, defaultOptions, trumbowyg.o.plugins.figcaption || {});
          let btnDef = {
            title: 'Imagem com legenda',
            hasIcon: false,
            fn: function () {
              trumbowyg.saveRange();
              
              let file,
              prefix = trumbowyg.o.prefix;
              
              let fields = {
                file: {
                  type: 'file',
                  required: true,
                  attributes: {
                    accept: 'image/*'
                  }
                },
                alt: { label: 'Texto Alternativo', value: trumbowyg.getRangeText(), required: true},
                caption: { label:'Legenda', value: '' }
              };
              
              if (trumbowyg.o.plugins.figcaption.imageWidthModalEdit) fields.width = { value: '' };
              
              // Prevent multiple submissions while uploading
              let isUploading = false;
              let $modal = trumbowyg.openModalInsert(trumbowyg.lang.figcaption, fields,
                // Callback
                (values) => {
                  if (isUploading) return; 
                  isUploading = true;
                  
                  let data = new FormData();
                  data.append(trumbowyg.o.plugins.figcaption.fileFieldName, file);
                  
                  trumbowyg.o.plugins.figcaption.data.map( cur => data.append(cur.name, cur.value) );
                  
                  $.map(values, (curr, key) => {
                    if (key !== 'file') data.append(key, curr); 
                  });
                  
                  if ($('.' + prefix + 'progress', $modal).length === 0) {
                    $('.' + prefix + 'modal-title', $modal).after(
                      $('<div/>', {'class':prefix+'progress'}).append($('<div/>',{'class':prefix+'progress-bar'}))
                      );
                    }
                    
                    $.ajax({
                      url: trumbowyg.o.plugins.figcaption.serverPath,
                      headers: trumbowyg.o.plugins.figcaption.headers,
                      xhrFields: trumbowyg.o.plugins.figcaption.xhrFields,
                      type: 'POST',
                      data: data,
                      cache: false,
                      dataType: 'json',
                      processData: false,
                      contentType: false,
                      
                      progressUpload: e => $('.' + prefix + 'progress-bar').css('width', Math.round(e.loaded * 100 / e.total) + '%'),
                      success: data => {
                        if (trumbowyg.o.plugins.figcaption.success) {
                          trumbowyg.o.plugins.figcaption.success(data, trumbowyg, $modal, values);
                        } else {
                          if (!!getDeep(data, trumbowyg.o.plugins.figcaption.statusPropertyName.split('.'))) {
                            let url = getDeep(data, trumbowyg.o.plugins.figcaption.urlPropertyName.split('.'));
                            trumbowyg.execCmd('insertHTML', `<p><figure><img src="${url}" alt="${values.alt}"><figcaption>${values.caption}</figcaption></figure></p><br/><p></p>`);
                            
                            let $img = $('img[src="' + url + '"]:not([alt])', trumbowyg.$box);
                            let $figcaption = $('img[src="' + url + '"] ~ figcaption', trumbowyg.$box);
                            if ($figcaption) $figcaption.text(values.caption != '' ? values.caption : 'Legenda...');
                            
                            $img.attr('alt', values.alt);
    
                            setTimeout(() => trumbowyg.closeModal(), 250);
                            trumbowyg.$c.trigger('tbwfigcaptionsuccess', [trumbowyg, data, url]);
                          } else {
                            trumbowyg.addErrorOnModalField( $('input[type=file]', $modal), trumbowyg.lang[data.message]);
                            trumbowyg.$c.trigger('tbwfigcaptionderror', [trumbowyg, data]);
                          }
                        }
                        isUploading = false;
                      },
                      
                      error: trumbowyg.o.plugins.figcaption.error || function () {
                        trumbowyg.addErrorOnModalField( $('input[type=file]', $modal), trumbowyg.lang.uploadError );
                        trumbowyg.$c.trigger('tbwfigcaptionerror', [trumbowyg]);
                        isUploading = false;
                      }
                    });
                  }
                  );
                  
                  $('.trumbowyg-modal').css("height", "");
                  $('form', $modal).after('<i style="padding-bottom: 1rem; color: red;">O ideal é a imagem respeitar o tamanho de 600x400</i>');
                  $('input[type=file]').on('change', function (e) {
                    try {
                      // If multiple files allowed, we just get the first.
                      file = e.target.files[0];
                    } catch (err) {
                      // In IE8, multiple files not allowed
                      file = e.target.value;
                    }
                  });
                }
              };
              
              trumbowyg.addBtnDef('figcaption', btnDef);
            }
          }
        }
      });
      
      function addXhrProgressEvent() {
        if (!$.trumbowyg.addedXhrProgressEvent) {   // Avoid adding progress event multiple times
          var originalXhr = $.ajaxSettings.xhr;
          $.ajaxSetup({
            xhr: function () {
              var that = this,
              req = originalXhr();
              
              if (req && typeof req.upload === 'object' && that.progressUpload !== undefined) {
                req.upload.addEventListener('progress', function (e) {
                  that.progressUpload(e);
                }, false);
              }
              
              return req;
            }
          });
          $.trumbowyg.addedXhrProgressEvent = true;
        }
      }
    })(jQuery);
    